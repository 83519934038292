import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import { ButtonTertiary } from "@anwb/button";
import {
  ReplacementVehicleContractUpdatedMessageDetails,
  ReplacementVehicleHandInMessageDetails,
  TypedStatusUpdateMessage,
} from "@status-updates/types";
import { FrontendState, StateMetadata } from "../frontend-state";
import { renderHandInInformation, renderPickUpInformation } from "./renderReplacementVehicleInfo";
import {
  determineInformationLinkText,
  determineRentalOpeningHourInformation,
} from "../../utils/countrySpecificText";
import { ButtonLink } from "../../components/link";
import { styledNameAndAddressWithMapsLink } from "../../utils/address-styler";

export class ReplacementVehicleContractUpdatedState extends FrontendState<
  ReplacementVehicleContractUpdatedMessageDetails | ReplacementVehicleHandInMessageDetails
> {
  constructor(
    message: TypedStatusUpdateMessage<
      ReplacementVehicleContractUpdatedMessageDetails | ReplacementVehicleHandInMessageDetails
    >,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "huurauto-sleutels",
      title: "Het huurcontract is aangepast",
    });
  }

  renderTimelineItems() {
    if (typeof this.message.messageDetails.handInDeadline === "string") {
      // TODO dit kan verwijderd worden wanneer deze change 3 maanden live staat.
      const replacementVehicleHandInMessage = this
        .message as TypedStatusUpdateMessage<ReplacementVehicleHandInMessageDetails>;

      return [
        {
          timestamp: DateTime.fromISO(replacementVehicleHandInMessage.created),
          title: "Het huurcontract is aangepast",
          content: (
            <Typography
              data-e2e-test-type="status-updates-replacement-vehicle-contractupdated"
              tagName="div"
            >
              <p>We hebben het huurcontract aangepast.</p>
              {this.renderCommonTimeLineItem(replacementVehicleHandInMessage, false)}
            </Typography>
          ),
          feedback: this.renderFeedback(),
        },
      ];
    }

    const message = this
      .message as TypedStatusUpdateMessage<ReplacementVehicleContractUpdatedMessageDetails>;
    const changedItems = [
      ...(message.messageDetails.pickUpDate?.isUpdated ? ["de ophaaldatum"] : []),
      ...(message.messageDetails.pickUpLocation?.isUpdated ? ["het ophaaladres"] : []),
      ...(message.messageDetails.handInDeadline?.isUpdated ? ["de inleverdatum"] : []),
      ...(message.messageDetails.handInLocation?.isUpdated ? ["het inleveradres"] : []),
    ];

    const changedItemsText = makeCommaSeparatedString(changedItems);

    return [
      {
        timestamp: DateTime.fromISO(message.created),
        title: "Het huurcontract is aangepast",
        content: (
          <Typography
            data-e2e-test-type="status-updates-replacement-vehicle-contractupdated"
            tagName="div"
          >
            <p>We hebben {changedItemsText} gewijzigd.</p>
            {(message.messageDetails.pickUpLocation?.isUpdated ||
              message.messageDetails.pickUpDate?.isUpdated) &&
              message.messageDetails.pickUpDate.date &&
              renderPickUpInformation(
                !message.messageDetails.handInDeadline.isUpdated ||
                  !message.messageDetails.handInLocation.isUpdated,
                false,
                {
                  name: message.messageDetails.pickUpLocation.name,
                  address: message.messageDetails.pickUpLocation.address,
                },
                message.messageDetails.pickUpDate.date,
                this.isComa,
                true
              )}
            {(message.messageDetails.handInDeadline.isUpdated ||
              message.messageDetails.handInLocation.isUpdated) &&
              renderHandInInformation(
                false,
                {
                  address: message.messageDetails.handInLocation.address,
                  name: message.messageDetails.handInLocation.name,
                },
                message.messageDetails.handInDeadline.deadline,
                this.isComa,
                !message.messageDetails.pickUpLocation?.isUpdated ||
                  !message.messageDetails.pickUpDate?.isUpdated
              )}
            <ButtonLink container={ButtonTertiary} href="informatie#vervangend-vervoer">
              {determineInformationLinkText(this.metadata.countryName, "vervangend vervoer")}
            </ButtonLink>
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }

  private renderCommonTimeLineItem(
    replacementVehicleHandInMessage: TypedStatusUpdateMessage<ReplacementVehicleHandInMessageDetails>,
    withOpeningTimes: boolean = true
  ): JSX.Element {
    const handInDeadline = DateTime.fromISO(
      replacementVehicleHandInMessage.messageDetails.handInDeadline,
      {
        locale: "nl-NL",
      }
    );
    const formattedHandInDate = handInDeadline.toFormat("d MMMM");
    const formattedHandInTime = handInDeadline.toLocaleString(DateTime.TIME_24_SIMPLE);
    const openingHourInformation = determineRentalOpeningHourInformation(
      replacementVehicleHandInMessage.messageDetails.handInLocation.address.countryCode
    );

    return (
      <>
        <p>
          <b>Inleveren</b>
          <br />
          {formattedHandInDate} voor {formattedHandInTime} uur
          <br />
          {styledNameAndAddressWithMapsLink(
            replacementVehicleHandInMessage.messageDetails.handInLocation.name,
            replacementVehicleHandInMessage.messageDetails.handInLocation.address
          )}
        </p>
        <p>
          Vergeet niet om de huurauto schoon in te leveren en de brandstoftank te vullen. Zorg
          ervoor dat {this.getInformalOrFormal("je", "u")} de huurauto voor het afgesproken tijdstip
          en op de afgesproken plaats inlevert. Hiermee{" "}
          {this.getInformalOrFormal("voorkom je", "voorkomt u")} extra kosten.
        </p>
        {withOpeningTimes && <p>{openingHourInformation}</p>}
      </>
    );
  }
}

export function makeCommaSeparatedString(array: string[]): string {
  const listStart = array.slice(0, -1).join(", ");
  const listEnd = array.slice(-1);
  const conjunction = array.length <= 1 ? "" : " en ";

  return [listStart, listEnd].join(conjunction);
}
