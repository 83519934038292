import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import {
  ReplacementVehiclePickUpMessageDetails,
  TypedStatusUpdateMessage,
} from "@status-updates/types";
import { styledNameAndAddressWithMapsLink } from "../../utils/address-styler";
import { determineRentalOpeningHourInformation } from "../../../src/utils/countrySpecificText";
import { FrontendState, StateMetadata } from "../frontend-state";
import { InlineLink } from "../../components/link";
import { renderPickUpInformation } from "./renderReplacementVehicleInfo";

export class ReplacementVehiclePickUpState extends FrontendState<ReplacementVehiclePickUpMessageDetails> {
  constructor(
    message: TypedStatusUpdateMessage<ReplacementVehiclePickUpMessageDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "huurauto-sleutels",
      title: "We hebben een huurauto gereserveerd",
    });
  }

  renderTimelineItems() {
    const handInDeadline = DateTime.fromISO(this.message.messageDetails.handInDeadline, {
      locale: "nl-NL",
    });
    const formattedReturnDate = handInDeadline.toFormat("d MMMM");
    const formattedReturnTime = handInDeadline.toLocaleString(DateTime.TIME_24_SIMPLE);

    const openingHourInformation = determineRentalOpeningHourInformation(
      this.message.messageDetails.rentalCompany.address.countryCode
    );

    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography data-e2e-test-type="status-updates-replacement-vehicle-pickup" tagName="div">
            {renderPickUpInformation(
              true,
              true,
              {
                name: this.message.messageDetails.rentalCompany.name,
                address: this.message.messageDetails.rentalCompany.address,
              },
              this.message.messageDetails.pickUpTimestamp,
              this.isComa,
              false
            )}

            {this.metadata.contractMarket === "coma" && (
              <p>
                {this.getInformalOrFormal("Je", "U")} moet in dit geval het vervoer naar het ophaalpunt zelf regelen, tenzij we andere afspraken met{" "}
                {this.getInformalOrFormal("jou", "u")} hebben gemaakt. Gelukkig vergoeden we de
                gemaakte kosten voor het openbaar vervoer (2e klas) naar het ophaalpunt. Bewaar de
                betaalbewijzen goed! Meer informatie over declareren{" "}
                {this.getInformalOrFormal("kun je", "kunt u")} vinden via deze{" "}
                <Typography
                  variant="link-text"
                  href="https://www.anwb.nl/wegenwacht/service/declareren"
                >
                  link
                </Typography>
                .{" "}
              </p>
            )}
            <p>
              {this.getInformalOrFormal("Haal je", "Haalt u")} de huurauto niet op, laat het ons dan
              weten via de belknop rechtsboven. Als {this.getInformalOrFormal("je", "u")} de huurauto niet ophaalt, brengen wij de kosten bij {this.getInformalOrFormal("jou", "u")} in rekening.
            </p>
            <p>
              <strong>Inleveren</strong>
              <br />
              {formattedReturnDate} voor {formattedReturnTime} uur
              <br />
              Locatie volgt in een latere update.
            </p>
            <p>{openingHourInformation}</p>
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
