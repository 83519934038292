export function determineRentalOpeningHourInformation(rentalCountry: string | undefined): string {
  if (rentalCountry === "FR") {
    return "Houd er rekening mee dat in Frankrijk de verhuurbedrijven gesloten zijn tussen 12:00 en 14:00 uur.";
  }
  return "Houd er rekening mee dat de verhuurbedrijven gesloten kunnen zijn tijdens de lunch.";
}

export function determineInformationPageTitle(countryName: string | undefined): string {
  return `Pech tijdens je reis in ${countryName}`;
}

export function determineInformationLinkText(subject: string): string {
  return `Meer over ${subject}`;
}
