import { DateTime } from "luxon";
import { Address } from "@status-updates/types";
import { styledNameAndAddressWithMapsLink } from "../../utils/address-styler";
import { determineRentalOpeningHourInformation } from "../../../src/utils/countrySpecificText";
import { getInformalOrFormal } from "../../routes/home/utils";
import { InlineLink } from "../../components/link";

export function renderHandInInformation(
  withOpeningTimes: boolean,
  handInLocation: { address: Address | undefined; name: string | undefined },
  handInDeadlineISO: string,
  isComa: boolean,
  isUpdated: boolean
): JSX.Element {
  const handInDeadline = DateTime.fromISO(handInDeadlineISO, {
    locale: "nl-NL",
  });
  const formattedHandInDate = handInDeadline.toFormat("d MMMM");
  const formattedHandInTime = handInDeadline.toLocaleString(DateTime.TIME_24_SIMPLE);

  return (
    <>
      <p>
        {isUpdated && (
          <>
            <strong>Nieuwe gegevens:</strong>
            <br />
          </>
        )}
        <b>Inleveren</b>
        <br />
        {formattedHandInDate} voor {formattedHandInTime} uur
        <br />
        {handInLocation.address
          ? styledNameAndAddressWithMapsLink(handInLocation.name, handInLocation.address)
          : "Locatie volgt in een latere update."}
      </p>
      <p>
        Vergeet niet om de huurauto schoon in te leveren en de brandstoftank te vullen. Zorg ervoor
        dat {getInformalOrFormal("je", "u", isComa)} de huurauto voor het afgesproken tijdstip en op
        de afgesproken plaats inlevert. Hiermee{" "}
        {getInformalOrFormal("voorkom je", "voorkomt u", isComa)} extra kosten.
      </p>
      {withOpeningTimes === true && handInLocation.address && (
        <p>{determineRentalOpeningHourInformation(handInLocation.address.countryCode)}</p>
      )}
    </>
  );
}

export function renderPickUpInformation(
  withList: boolean,
  withInformation: boolean,
  pickUpLocation: { address: Address | undefined; name: string | undefined },
  pickUpTimeStampISO: string,
  isComa: boolean,
  isUpdated: boolean
): JSX.Element {
  const pickUpTimestamp = DateTime.fromISO(pickUpTimeStampISO, {
    locale: "nl-NL",
  });
  const formattedPickUpDate = pickUpTimestamp.toFormat("d MMMM");
  const formattedPickUpTime = pickUpTimestamp.toLocaleString(DateTime.TIME_24_SIMPLE);

  return (
    <>
      {withList && (
        <p>
          Neem het volgende mee bij het ophalen van de huurauto:
          <ul style={{ listStyleType: "disc" }}>
            <li>Pinpas en creditcard</li>
            <li>Rijbewijs</li>
            <li>ID of paspoort</li>
          </ul>
        </p>
      )}
      {withInformation && (
        <p>
          Meer informatie en tips over het ophalen van de huurauto{" "}
          {getInformalOrFormal("kun je", "kunt u", isComa)} vinden via deze{" "}
          <InlineLink href="informatie#vervangend-vervoer">link</InlineLink>.{" "}
        </p>
      )}
      <p>
        {isUpdated && (
          <>
            <strong>Nieuwe gegevens:</strong>
            <br />
          </>
        )}
        <strong>Ophalen</strong>
        <br />
        {formattedPickUpDate} vanaf {formattedPickUpTime} uur
        <br />
        {styledNameAndAddressWithMapsLink(pickUpLocation.name, pickUpLocation.address)}
      </p>
    </>
  );
}
